import React from "react";
import classNames from "classnames";

import { AugurTextLogo, v2AugurLogo, AugurBetaLogo, AugurBetaTextLogo } from "./icons";
import { useAppStatusStore } from "../../stores/app-status";
import { MarketsLink } from "../../utils/links/links";

import Styles from "./logo.styles.less";

// Import your PNG images
import AugurBetaLogoPng from "../../assets/images/Predictn/fulllogo_transparent.png";
import AugurBetaTextLogoPng from "../../assets/images/Predictn/fulllogo_transparent.png";

import AugurBetaLogoTransparent from "../../assets/images/Predictn/icononly_transparent.png";

export interface LogoProps {
  isMobile?: boolean;
  darkTheme?: boolean;
}

export const Logo = ({ isMobile, darkTheme }: LogoProps) => (
  <section className={classNames(Styles.v2Logo, { [Styles.Dark]: darkTheme })}>
    {isMobile ? <img
      src={AugurBetaLogoTransparent}
      alt=" Beta Logo"
      className={Styles.LogoImage}
    /> : <img
      src={AugurBetaLogoTransparent}
      alt=" Beta Logo"
      className={Styles.LogoImage}
    />}
  </section>
);

export const LinkLogo = ({ alwaysFull = false }) => {
  const { isMobile } = useAppStatusStore();

  return (
    <MarketsLink id="logolink">
      <section aria-label="Augur markets list page link" className={Styles.LogoBeta}>
        {isMobile && !alwaysFull ? (
          <img
            src={AugurBetaLogoPng}
            alt="Augur Beta Logo"
            className={Styles.LogoImage}
          />
        ) : (
          <img
            src={AugurBetaTextLogoPng}
            alt="Augur Beta Text Logo"
            className={Styles.LogoImage}
          />
        )}
      </section>
    </MarketsLink>
  );
};

export default Logo;
